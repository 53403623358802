import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import styled from "styled-components";
import {
  height,
  HeightProps,
  // @ts-ignore
  typography,
  // @ts-ignore
  TypographyProps
} from "styled-system";
import Button from "../components/Button";
import CallToAction from "../components/CallToAction";
import Box from "../components/elements/Box";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import AdvantageSection from "../container/AdvantageSection";
import MigrationStepSection from "../container/MigrationStepSection";
import NeedToKnowSection from "../container/NeedToKnowSection";
import PropertySection from "../container/PropertySection";
import { contacts } from "../lib/config";
import { theme } from "../lib/theme";

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="投資移民" />

    <StyledBackgroundImage
      fluid={data.heroBg.childImageSharp.fluid}
      height={["50vh", "60vh", 806]}
    >
      <Box
        pt={theme.headerHeight}
        px={[0, 0, 1]}
        height="100%"
        width={1}
        maxWidth={1200}
        mx="auto"
        flexBox
        justifyContent={["center", "center", "flex-start"]}
        alignItems={["center"]}
      >
        <Box>
          <Fade>
            <HeroText
              fontSize={["32px", "64px"]}
              display="block"
              fontWeight="bold"
              color="white"
            >
              <HeroText color={"#F1CF22"}>投資</HeroText>
              希臘樓
            </HeroText>
            <HeroText
              fontSize={["32px", "64px"]}
              fontWeight="bold"
              color="white"
              css={`
                margin-left: 4rem;
                display: block;
              `}
            >
              <HeroText color={"#F1CF22"}>移民</HeroText>
              不是夢
            </HeroText>
          </Fade>

          <Zoom>
            {/* <Link to="/seminar/"> */}
            <Button
              as="a"
              href={contacts.whatsapp.link}
              target="_blank"
              rel="noopener"
              mt={3}
              width={[1]}
              fontSize={[3, 4]}
              variant="none"
              bg="info"
            >
              免費諮詢
            </Button>
            {/* </Link> */}
          </Zoom>
        </Box>
      </Box>
    </StyledBackgroundImage>
    {/* End of Hero */}

    <AdvantageSection />
    <CallToAction />
    <NeedToKnowSection showButton />
    <MigrationStepSection showButton bg="secondary" />
    <PropertySection showButton />
    <CallToAction />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  {
    heroBg: file(relativePath: { eq: "hero-background.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    needToKnows: allFile(
      filter: { relativePath: { regex: "/need-to-know/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    advantages: allFile(
      filter: { relativePath: { regex: "/advantage-/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(quality: 100, height: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`;

const HeroText = styled("span")<TypographyProps>`
  ${typography}
  color: ${props => (props.color ? props.color : props.theme.colors.default)};
`;

const StyledBackgroundImage = styled(BackgroundImage)<HeightProps>`
  ${height}
  width: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
`;
