import styled from "styled-components"

const AdvangtageSectionWrapper = styled("section")`
  * {
    box-sizing: border-box;
  }

  .advantage__card {
    border: 0.5px solid ${props => props.theme.colors.border};
    // min-height: 320px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-evenly;
    // align-items: center;
    transition: box-shadow 0.3s ease 0s;
    transition-property: box-shadow;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;

    &:hover {
      // box-shadow: 0px 6px 6px 2px rgba(0, 0, 0, 0.16);
      // box-shadow: 0px 5px 60px 0px rgba(27, 67, 111, 0.15);
      box-shadow: 0 40px 90px -30px rgba(39, 79, 117, 0.2);
      text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
    }
  }
`

export default AdvangtageSectionWrapper
