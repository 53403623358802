import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Box from "../../components/elements/Box";
import Text from "../../components/elements/Text";
import Section from "../../components/Section";
import AdvangtageSectionWrapper from "./advantage.style";

const advantageText = [
  "投資25萬歐元以上房產(大約220萬港元)，一家三代同時獲得居留身份",
  "不需坐移民監。可在香港繼續生活，不影響希臘身份",
  "不需面試，不受學歷及語言限制",
  "2個月獲批，特快擁有居留身份",
  "當地樓價2008-2017年共下跌42%。2018年開始急升，升值潛力強勁",
  "世界旅遊城巿，租金回報平均8-10%",
  "地中海風景，氣候適中，優良的教育及醫療福利",
  "自由通行歐洲26國"
];

const AdvantageSection = () => {
  const { advantages } = useStaticQuery(graphql`
    {
      advantages: allFile(
        filter: { relativePath: { regex: "/advantage-/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fixed(quality: 100, height: 200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);
  return (
    <AdvangtageSectionWrapper>
      <Section title="希臘投資優勢">
        <Box flexBox flexWrap="wrap" width={[1]} px={[2, 2, 0]}>
          {advantages.edges.map(({ node }, i) => (
            <Box
              key={`advantage-${i}`}
              className="advantage__card"
              px={[2, 4]}
              py={[3]}
              width={[1, 1 / 2, 1 / 3]}
              flexBox
              flexDirection="column"
              justifyContent="space-evenly"
              alignItems="center"
              minHeight={320}
            >
              <Img fixed={node.childImageSharp.fixed} />
              <Text
                textAlign="center"
                p={2}
                fontSize={[2, 3]}
                content={advantageText[i]}
                lineHeight="1.5"
              ></Text>
            </Box>
          ))}
        </Box>
      </Section>
    </AdvangtageSectionWrapper>
  );
};

export default AdvantageSection;
